export default {
  au: "Aarhus University",
  cu: "Concordia University ",
  cub: "Corvinus University of Budapest",
  hec: "HEC Montréal",
  hku: "Hong Kong University of Science and Technology ",
  iu: "Indiana University ",
  isu: "Iowa State University",
  ivey: "Ivey Business School ",
  mu: "Maastricht University ",
  // tum: "Technical University Munich",
  up: "Universidad Panamericana",
  uofh: "University of Houston",
  uofm: "University of Minnesota",
  uoft: "University of Toronto",
  wlu: "Wilfrid Laurier University",
}
